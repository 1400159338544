import StartPage from './pages/StartPage';
import StartOverview from "./pages/StartPage/StartOverview";

const pages = [
    {
      path: '/',
      redirect: '/start-page'
    },
    {
      path: '/start-page',
      name: 'StartPage',
      component: StartPage,
      children: [
        {
          path: '',
          redirect: '/start-page/overview'
        },
        {
          path: 'overview',
          name: 'StartOverview',
          component: StartOverview
        }
      ]
    }
];

import {computed} from 'vue';
import { i18n } from './i18n';
const { t } = i18n.global;

const menuItems = computed(() => [{
            slug: 'start-page',
            path: '/start-page',
            name: 'StartPage',
            label: t('eidhub.pages.menuItems.startPage.linkLabel'),
            children: [
                {
                    path: '/start-page/overview',
                    name: 'StartOverview',
                    label: t('eidhub.pages.menuItems.startPage.children.overview.linkLabel')
                }
            ]
        }]);

    
export {
    pages,
    menuItems
}